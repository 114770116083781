// @ts-expect-error import error
import oneVsOneRiveAnimation from 'shared/animations/1vs1.riv'
// @ts-expect-error import error
import bullsEyeRiveAnimation from 'shared/animations/bullsEye.riv'
// @ts-expect-error import error
import setupsRiveAnimation from 'shared/animations/setups.riv'
// @ts-expect-error import error
import upDownRiveAnimation from 'shared/animations/upDown.riv'
import { RouterPathes } from 'shared/constants'
import {
  modeRewardsCardIconPath,
  modeSetupsCardIconPath,
  modeBullsEyeCardIconPath,
  modeOneVsOneCardIconPath,
  modeUpDownCardIconPath,
  scullWithBg,
  OneVsOneIcon,
  BullsEyeIcon,
  UpDownIcon,
  RewardsIcon,
  SetupsIcon
} from 'shared/icons'
import { HeroBannerType } from 'shared/types'
import { CardMode } from './types'

export type HomeCardType = CardMode | OnboardingCard
interface OnboardingCard {
  key: 'howto'
  title: string
  callToAction: string
}
export const CARD_MODES: HomeCardType[] = [
  {
    key: 'howto',
    title: 'Explore game modes',
    callToAction: 'How to play'
  },
  {
    key: 'rewards',
    title: 'Rewards',
    color: 'var(--c-a-sky)',
    logo: modeRewardsCardIconPath,
    backgroundElement: RewardsIcon,
    path: RouterPathes.rewards,
    description:
      'Daily Tasks and Challenges! Jump in to claim your XYRO points and score rewards!'
  },
  {
    key: 'onevsone',
    title: '1 vs 1',
    color: 'var(--c-one-vs-one)',
    logo: modeOneVsOneCardIconPath,
    backgroundElement: OneVsOneIcon,
    path: RouterPathes.oneVsOne,
    description:
      'Real crypto duels! Compete in private or public matches, and outplay others to win!'
  },
  {
    key: 'bullseye',
    title: 'Bull’s Eye',
    color: 'var(--c-bulls-eye)',
    logo: modeBullsEyeCardIconPath,
    backgroundElement: BullsEyeIcon,
    path: RouterPathes.bullsEye,
    description:
      'Test your skills! Guess BTC’s most accurate price and claim your victory!'
  },
  {
    key: 'setup',
    title: 'Setups',
    color: 'var(--c-setups)',
    logo: modeSetupsCardIconPath,
    backgroundElement: SetupsIcon,
    path: RouterPathes.setups,
    description:
      'Join trading ideas! Choose TP or SL, compete with others, and win if you’re right!'
  },
  {
    key: 'updown',
    title: 'Up / Down',
    color: 'var(--c-up-down)',
    logo: modeUpDownCardIconPath,
    backgroundElement: UpDownIcon,
    path: RouterPathes.upDown,
    description:
      'Quick and fun for everyone! Guess Bitcoin’s next move and win every minute!'
  }
]

export const APP_ACHIEVEMENTS_MOCK = {
  cryptoOracle: {
    title: 'Crypto oracle',
    description:
      'Successfully predict and win 10 consecutive 1vs1 crypto games',
    imagePath: scullWithBg
  }
}

export const BANNERS: HeroBannerType[] = [
  'web3',
  'chainlink',
  'bullsEye',
  'upDown',
  'twitter',
  'discord'
]

export const RIVE_CARDS_SOURCES = {
  bullseye: bullsEyeRiveAnimation,
  updown: upDownRiveAnimation,
  onevsone: oneVsOneRiveAnimation,
  setup: setupsRiveAnimation
}
