import { useCallback, useState } from 'react'
import { Flex, Table } from '@radix-ui/themes'
import {
  ColumnDef,
  PaginationState,
  Row,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import cn from 'classnames'
import { DataTestIDs } from 'shared/constants'
import { TableEmptyState } from './table-empty-state'
import { OpenDetailsCell } from './table-items/table-cell-open-details'
import { TablePagination } from './table-pagination'
import styles from '../table.module.scss'

// TODO: move BetsTable to the features
interface Props<TEntity> {
  onDetailsCellClick?: (bet: TEntity) => void
  openDetailsButtonDataTestID?: DataTestIDs | ''
  bets: TEntity[]
  columns: ColumnDef<TEntity, any>[]
  emptyStateText?: string
  tableId?: string // NOTE: to generate unique keys
  pageSize?: number
  className?: string
  tableContentLineClassname?: string
  isPaginationEnabled?: boolean
}
export const BetsTable = <TEntity,>({
  onDetailsCellClick,
  openDetailsButtonDataTestID,
  bets,
  columns,
  emptyStateText = '',
  tableId = 'default',
  pageSize = 5,
  className = '',
  tableContentLineClassname = '',
  isPaginationEnabled = true
}: Props<TEntity>) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize,
    pageIndex: 0
  })
  const table = useReactTable({
    data: bets,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination
    }
  })

  const handleDetailsCellClick = useCallback(
    (row: Row<TEntity>) => {
      if (!onDetailsCellClick) return
      onDetailsCellClick(row.original)
    },
    [onDetailsCellClick]
  )

  if (!bets || bets.length === 0) {
    return <TableEmptyState text={emptyStateText} />
  }

  return (
    <Flex
      position={'relative'}
      width={'100%'}
      direction={'column'}
    >
      <Table.Root className={cn(styles.tableRoot, className)}>
        <Table.Header className={styles.tableHeaderClassname}>
          {table.getHeaderGroups().map(({ id, depth, headers }) => {
            return (
              <Table.Row key={`${tableId}-${id}-${depth}`}>
                {headers.map(header => (
                  <Table.ColumnHeaderCell
                    key={`${tableId}-${header.depth}-${header.column.id}-${header.index}`}
                    className={styles.tableHeaderCell}
                  >
                    {header.isPlaceholder ? null : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )
                    )}
                  </Table.ColumnHeaderCell>
                ))}

                {/* NOTE: empty header for details button */}
                <Table.ColumnHeaderCell className={styles.tableHeaderCell} />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body>
          {table.getRowModel().rows.map(row => (
            <Table.Row
              key={row.id}
              className={cn(
                styles.tableBodyRowClassname,
                tableContentLineClassname
              )}
            >
              {row.getAllCells().map(cell => (
                <Table.Cell
                  key={`${cell.id}-${cell.column.id}`}
                  className={styles.tableBodyCell}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}

              {onDetailsCellClick && (
                <OpenDetailsCell
                  dataTestID={openDetailsButtonDataTestID}
                  onClick={() => handleDetailsCellClick(row)}
                />
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      {isPaginationEnabled ?
        <TablePagination<TEntity> table={table} />
      : null}
    </Flex>
  )
}
