import React, { useEffect } from 'react'
import { Flex } from '@radix-ui/themes'
import { Fit, Layout, useRive } from '@rive-app/react-canvas'
import { useNavigate } from 'react-router-dom'
import { RIVE_CARDS_SOURCES } from '../constants'
import styles from '../home.module.scss'

interface Props {
  mode: 'onevsone' | 'bullseye' | 'updown' | 'setup'
  path: string
}
export const ModeCardRiveWrapper: React.FC<Props> = ({ mode, path }) => {
  const navigate = useNavigate()

  const animationSource = RIVE_CARDS_SOURCES[mode]
  let animationPauseTimeout: NodeJS.Timeout

  const { rive, RiveComponent } = useRive({
    src: animationSource,
    layout: new Layout({
      fit: Fit.Fill
    }),
    autoplay: false
  })

  useEffect(() => {
    return () => {
      rive?.removeAllRiveEventListeners()
      clearTimeout(animationPauseTimeout)
    }
  }, [])

  const handleMouseEnter = () => {
    if (!rive) return

    rive.play()

    animationPauseTimeout = setTimeout(() => {
      rive.pause()
    }, 4000)
  }

  const handleMouseLeave = () => {
    if (!rive) return

    rive.play()
    rive.reset()
    clearTimeout(animationPauseTimeout)
  }

  return (
    <Flex
      className={styles.riveAnimationcontainer}
      height={'100%'}
      width={'100%'}
    >
      <RiveComponent
        onClick={() => navigate(path)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Flex>
  )
}
