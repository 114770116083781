import { Flex, Heading, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { useResponsive } from 'shared/hooks/use-responsive'
import { HomeCardType } from '../constants'
import { ModeCallToActionCard } from './mode-call-to-action-card'
import { ModeCardRiveWrapper } from './mode-card-rive-wrapper'
import { ModeCardWrapper } from './mode-card-wrapper'
import styles from '../home.module.scss'

export interface Props {
  mode: HomeCardType
  isWide?: boolean
}
export const ModeCard: React.FC<Props> = ({ mode, isWide = false }) => {
  const [isMobile] = useResponsive(['xs', 'sm'])

  if (mode?.key === 'howto')
    return (
      <ModeCallToActionCard
        title={mode.title}
        callToAction={mode.callToAction}
      />
    )

  const {
    title,
    logo,
    path,
    backgroundElement: BackgroundModeIcon,
    description
  } = mode

  if (
    (mode.key === 'onevsone' ||
      mode.key === 'updown' ||
      mode.key === 'bullseye' ||
      mode.key === 'setup') &&
    !isMobile
  ) {
    return (
      <ModeCardRiveWrapper
        path={path}
        mode={mode.key}
      />
    )
  }

  return (
    <ModeCardWrapper
      path={path}
      modeKey={mode.key}
    >
      <img
        src={logo}
        alt={`${title} logo`}
        className={cn(styles.cardLogo, styles[mode.key])}
      />

      <Flex
        className={styles.modeCardInfoContainer}
        direction={'column'}
        align={'center'}
        height={'100%'}
        width={'100%'}
        p={{ initial: '2', md: '5' }}
        py={{ initial: '3' }}
        gap={'2'}
      >
        <Heading
          weight={'bold'}
          size={'8'}
          className={cn(styles.cardTitle, {
            [styles.cardTitleLarge]: isWide
          })}
        >
          {title}
        </Heading>

        <Text
          className={cn('color-white', styles.cardDescription, {
            [styles.cardDescriptionLarge]: isWide
          })}
        >
          {description}
        </Text>
      </Flex>

      <BackgroundModeIcon
        className={cn(styles.cardBackgroundElement, styles[mode.key])}
      />
    </ModeCardWrapper>
  )
}
